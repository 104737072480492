import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../components/contactus-page/hero/Hero.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'
import Contact from '../components/contactus-page/contact/Contact'
import Location from '../components/contactus-page/Location/Location'

const ContactusPage = (props) => {
    const {strapiContactUs} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiContactUs.metaTitle}
                description={strapiContactUs.metaDescription}
                keywords={strapiContactUs.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <Contact />
            <Location />
        </Layout>
    )
}

export default ContactusPage

const query = graphql`
    query {
        strapiContactUs {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
