import React, {useEffect} from 'react'
import styled from 'styled-components'

import {navigate} from 'gatsby'

const Location = () => {
    const openMaps = () => {
        console.log('HERE BRO')
        navigate('https://google.com')
    }

    useEffect(() => {
        document.getElementById('location-frame').addEventListener('click', openMaps)

    })

    return (
        <StyledLocation>
            <Wrapper>
                <H2>OUR OFFICES</H2>
                <Addresses>
                    <Address>
                        <P>Emaar Square - Building 6</P>
                        <P>Office 404, P.O. Box: 114683, Dubai, UAE</P>
                        <Details>
                            <Additional href={"tel:+97142910333"}>Tel. +971 4 291 0333</Additional>
                        </Details>
                    </Address>
                </Addresses>
            </Wrapper>
            <Map>
                <IframeWrapper>
                    <iframe id={'location-frame'} width="100%" height="700px" frameBorder="0" scrolling="no"
                            marginHeight="0" marginWidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Emaar%20square%20building%206+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                    </iframe>
                    <Redirect href={'https://maps.google.com/maps?ll=25.201496,55.274463&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=1354994934497181572'} target={'_blank'}></Redirect>
                </IframeWrapper>
            </Map>
        </StyledLocation>
    )
}

export default Location

const IframeWrapper = styled.div`
  z-index: 0;
  position: relative;
`

const Redirect = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 700px;
  z-index: 5;
`

const StyledLocation = styled.div`
  padding-left: 12rem;
  padding-right: 12rem;
  padding-bottom: 16.8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 0;
    padding-bottom: 3rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 0 15vw 16.8rem 15vw;
  }
`

const Wrapper = styled.div`
  //margin-right: 12rem;
  //margin-left: 12rem;
  //margin-bottom: 16.8rem;
  display: flex;
  flex-direction: column;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin: 0;
    padding: 3rem 2.4rem 4rem 2.4rem;
  }
`

const Addresses = styled.div`
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;
  gap: 17.2rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 4.2rem;
  }
`

const Address = styled.div`
  max-width: 49.7rem;
  display: flex;
  flex-direction: column;
`

const H2 = styled.h2`
  font-family: Sequel85;
  font-size: 3.2rem;
  color: #0C2637;
  justify-content: flex-start;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-family: Sequel75;
    font-size: 2rem;
    line-height: 2.8rem;
  }
`

const P = styled.p`
  font-family: Sequel45;
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 400;
  color: #0C2637;
`

const Details = styled.div`
  margin-top: 2rem;
`

const Additional = styled.a`
  font-family: Sequel45;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #3B869D;
`

const Map = styled.div`
  height: 700px;
  width: 100%;
  margin-top: 5.6rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 0;
  }
`
